@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Orbitron;
  src: url("./assets/fonts//Orbitron-Regular.ttf");
  font-weight: 400;
}

@font-face {
  font-family: Orbitron;
  src: url("./assets/fonts//Orbitron-Medium.ttf");
  font-weight: 500;
}

@font-face {
  font-family: Orbitron;
  src: url("./assets/fonts//Orbitron-SemiBold.ttf");
  font-weight: 600;
}

@font-face {
  font-family: Orbitron;
  src: url("./assets/fonts//Orbitron-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: Orbitron;
  src: url("./assets/fonts//Orbitron-ExtraBold.ttf");
  font-weight: 800;
}

@font-face {
  font-family: Orbitron;
  src: url("./assets/fonts//Orbitron-Black.ttf");
  font-weight: 900;
}

* {
  font-family: Orbitron;
}
